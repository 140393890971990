import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { updatePassword } from '../services/api';

const ChangePasswordForm = ({ onCancel }) => {
    const userEmail = localStorage.getItem('loginEmail') || "";

    const [passwordData, setPasswordData] = useState({
        email: userEmail,
        newPassword: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setPasswordData({
            ...passwordData,
            [e.target.name]: e.target.value,
        });
        setErrors({ ...errors, [e.target.name]: '' });
    };

    const validateForm = () => {
        const newErrors = {};
        const { newPassword, confirmPassword } = passwordData;

        if (newPassword.length < 6) {
            newErrors.newPassword = 'Password must be at least 6 characters long.';
        }
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            const { email , newPassword } = passwordData;

            const response = await updatePassword(email, newPassword);
            if (response.success) {
                NotificationManager.success(response.message || 'Password changed successfully!');
                onCancel();
            } else {
                NotificationManager.error(response.message || 'Update Password Failed');
            }
        } catch (error) {
            NotificationManager.error('An error occurred. Please try again later.', 'Error');
        }
    };

    return (
        <form className="change-password-form" onSubmit={handleSubmit}>
            <h3>Change Password</h3>
            <label>
                Email:
                <input type="email" name="email" value={passwordData.email} readOnly />
            </label>
            <label>
                New Password:
                <input 
                    type="password" 
                    name="newPassword" 
                    value={passwordData.newPassword} 
                    onChange={handleChange} 
                />
                {errors.newPassword && <p className="error">{errors.newPassword}</p>}
            </label>
            <label>
                Confirm New Password:
                <input 
                    type="password" 
                    name="confirmPassword" 
                    value={passwordData.confirmPassword} 
                    onChange={handleChange} 
                />
                {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
            </label>
            <div className='d-flex justify-content-between w-50 text-center' style={{ margin: '0 auto' }}>
                <button type="submit" className="save-button">Update</button>
                <button type="button" className="cancel-button" onClick={onCancel}>Cancel</button>
            </div>
        </form>
    );
};

export default ChangePasswordForm;
