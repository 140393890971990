import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/css/Home.css';
import LoginLogo from '../assets/images/LoginLogo.png';

const Home = () => {
    const navigate = useNavigate();

      useEffect(() => {
        const timer = setTimeout(() => {
          navigate('/login');
        }, 3000);

        return () => clearTimeout(timer);
      }, [navigate]);

    return (
        <div className="home-container">
            <div className="overlay"></div>
            <div className="content">
                <div className="logologin">
                    <img src={LoginLogo} alt="UCB Logo" />
                </div>      
            </div>
        </div>
    );
};

export default Home;
