import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaFileInvoiceDollar, FaFolder, FaUserCog, FaSignOutAlt, FaBars } from 'react-icons/fa';
import '../assets/css/Sidebar.css';

const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => setIsOpen(!isOpen);
    const handleLogout = () => {
        localStorage.removeItem('token');
    }
    return (
        <>
            <button style={{width:'46px'}} className="sidebar-toggle" onClick={toggleSidebar}>
                <FaBars />
            </button>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <nav>
                    <ul>
                        <li>
                            <NavLink exact to="/dashboard" activeClassName="active">
                                <FaTachometerAlt /> <span className='px-3'>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/invoice-details" activeClassName="active">
                                <FaFileInvoiceDollar /> <span className='px-3' >Invoice Details</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/myaccount" activeClassName="active">
                                <FaUserCog /> <span className='px-3' >My Account</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/login" onClick={handleLogout} activeClassName="active" style={{marginTop: '120%', marginLeft: '20%'}}>
                                <FaSignOutAlt /> <span className='px-3' >Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
};

export default Sidebar;