import React from 'react';
import '../assets/css/Sidebar.css';
import Logo from '../assets/images/HeaderLogo.png'

const Header = () => {
  return (
    <header className="header">
      <div className='header-content'>
        <img className="logo" src={Logo} alt='UCB Logo' />
      </div>
    </header>
  );
};

export default Header;