import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import '../assets/css/Dashboard.css';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { getDasnboardInvoiceDetails } from '../services/api';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [data, setData] = useState({
    attendance: {
      labels: [],
      datasets: [],
    },
    bills: {
      labels: [],
      datasets: [],
    },
  });
  const [globalData, setGlobalData] = useState({});
  const [loading, setLoading] = useState(true);
  const [attendanceYear, setAttendanceYear] = useState('');
  const [billsYear, setBillsYear] = useState('');
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getDasnboardInvoiceDetails();
        if (response.success) {
          const Detias = response.data;
          await setGlobalData(Detias);
          const years = Detias.ListofYears.map((item) => item.Year);
          await setYearOptions(years);
          setAttendanceYear(years[0]);
          setBillsYear(years[0]);
          await handleDashboardAttendenceUpdate(years[0], Detias);
          await handleDashboardBillingUpdate(years[0], Detias);
        } else {
          NotificationManager.error(response.message || 'Something went wrong..Retrieve data');
        }
      } catch (error) {
        NotificationManager.error('Error fetching data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDashboardAttendenceUpdate = (selectedYear, Data) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const yearData = Data.MonthBased?.filter(item => item.Year == selectedYear) || [];

    const datasetData = months.map(month => {
      const monthData = yearData.find(item => item.Month === month);
      return monthData ? parseFloat(monthData['Attendance']) : 0;
    });

    setData(prevData => ({
      ...prevData,
      ['attendance']: {
        labels: months,
        datasets: [
          {
            // label: selectedYear,
            data: datasetData,
            backgroundColor: ['#4CAF50', '#F44336', '#FFC107', '#03A9F4', '#9C27B0', '#FF5722', '#FFEB3B', '#009688', '#3F51B5', '#795548', '#8BC34A', '#E91E63'],
          },
        ],
      },
    }));
  };
  const handleDashboardBillingUpdate = (selectedYear , Data) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const yearData = Data.MonthBased?.filter(item => item.Year == selectedYear) || [];

    const datasetData = months.map(month => {
      const monthData = yearData.find(item => item.Month === month);
      return monthData ? parseFloat(monthData['TotalBills']) : 0;
    });

    setData(prevData => ({
      ...prevData,
      ['bills']: {
        labels: months,
        datasets: [
          {
            // label: selectedYear,
            data: datasetData,
            backgroundColor: ['#4CAF50', '#F44336', '#FFC107', '#03A9F4', '#9C27B0', '#FF5722', '#FFEB3B', '#009688', '#3F51B5', '#795548', '#8BC34A', '#E91E63'],
          },
        ],
      },
    }));
  };

  const handleAttendanceYearChange = (event) => {
    const selectedYear = event.target.value;
    setAttendanceYear(selectedYear);
    handleDashboardAttendenceUpdate(selectedYear, globalData);
  };

  const handleBillsYearChange = (event) => {
    const selectedYear = event.target.value;
    setBillsYear(selectedYear);
    handleDashboardBillingUpdate(selectedYear, globalData);
  };

  const attendenceOptions = {
    responsive: true,
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, grid: { drawBorder: false } },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: context => "Total Attendance" + ": " + context.raw,
          title: tooltipItems => {
            const tooltipItem = tooltipItems[0];
            return "Month: " + tooltipItem.label + " " + attendanceYear;
          },
        },
      },
    },
  };


  const billingOptions = {
    responsive: true,
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, grid: { drawBorder: false } },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: context => "Total Bills" + ": " + context.raw,
          title: tooltipItems => {
            const tooltipItem = tooltipItems[0];
            return "Month: " + tooltipItem.label + " " + attendanceYear;
          },
        },
      },
    },
  };

  return (
    <div className="dashboard-container">
      <Header />
      <div className="dashboard-content">
        <Sidebar />
        <main className="main-content">
          {loading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <p className="loading-text">Loading...</p>
            </div>
          ) : (
            <div className="dashboard-scrollable-content">
              <div className='d-flex justify-content-between w-100'>
                <div className='w-50 mx-3'>
                  <div className="attendance-section my-4">
                    <div className="chart-controls">
                      <div className='d-flex justify-content-between'>
                        <h2 className='myheadings mb-0'>Attendance</h2>
                        <div className='w-50' style={{ textAlign: 'end' }}>
                          <select
                            className='selectbox'
                            value={attendanceYear}
                            onChange={handleAttendanceYearChange}
                          >
                            {yearOptions.map((year) => (
                              <option key={year} value={year}>{year}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {data.attendance.labels.length > 0 && <Bar data={data.attendance} options={attendenceOptions} />}
                  </div>
                  <div className="bills-section my-4">
                    <div className="chart-controls">
                      <div className='d-flex justify-content-between'>
                        <h2 className='myheadings mb-0'>Total Bills</h2>
                        <div className='w-50' style={{ textAlign: 'end' }}>
                          <select
                            className='selectbox'
                            value={billsYear}
                            onChange={handleBillsYearChange}
                          >
                            {yearOptions.map((year) => (
                              <option key={year} value={year}>{year}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {data.bills.labels.length > 0 && <Bar data={data.bills} options={billingOptions} />}
                  </div>
                </div>
                <div className='w-50 mx-3'>
                  <div className="total-attendance my-4">
                    <h2 className='myheadings2'>Total Attendance</h2>
                    <ul style={{ listStyleType: 'none', lineHeight: '3' }}>
                      {globalData.YearBased?.map((item) => (
                        <li key={item.Year} className='d-flex justify-content-between'>
                          <span>{item.Year}</span>
                          <div>
                            <span className='px-2'>{parseFloat(item.TotalAttendance).toFixed(2)}</span>
                            <span style={{ color: 'green' }} className='trend-up'>
                              ↑
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="total-bills my-4">
                    <h2 className='myheadings2'>Total Bills</h2>
                    <ul style={{ listStyleType: 'none', lineHeight: '3' }}>
                      {globalData.YearBased?.map((item) => (
                        <li key={item.Year} className='d-flex justify-content-between'>
                          <span>{item.Year}</span>
                          <div>
                            <span className='px-3'>{parseFloat(item.TotalBills).toFixed(2)}</span>
                            <span style={{ color: 'green' }} className='trend-up'>
                              ↑
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
