import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useNavigate } from 'react-router-dom';
import LoginLogo from '../assets/images/LoginLogo.png';
import '../assets/css/Login.css';
import { loginUser } from '../services/api';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();

    const validateForm = () => {
        let isValid = true;
        let errors = {};

        if (!email) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid';
            isValid = false;
        }

        if (!password) {
            errors.password = 'Password is required';
            isValid = false;
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
        try {
            const response = await loginUser(email, password);
            if (response.success) {
                localStorage.setItem('token', response?.token)
                localStorage.setItem('loginEmail', response?.data?.email)
                NotificationManager.success(response.message);
                navigate('/dashboard');
            } else {
                NotificationManager.error(response.message || 'Login failed');
            }
        } catch (error) {
            NotificationManager.error('An error occurred. Please try again later.', 'Error');
        }
    }
    };

    

    const handleInitialPage = () => {
        navigate('/');
    }

    return (
        <div className="login-page">
            <NotificationContainer />
            <div className="image-section"></div>
            <div className="login-section">
                <div className="login-container">
                    <div className="logologin">
                        <img src={LoginLogo} alt="UCB Logo" onClick={handleInitialPage}/>
                    </div>
                    <h2>LOGIN</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email Id</label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Enter your Email Id"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <div className="password-input">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    placeholder="Enter your Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </span>
                                {errors.password && <span className="error">{errors.password}</span>}
                            </div>
                        </div>
                        <div className="forgot-password">
                            <a href="/forgot-password">Forgot Password?</a>
                        </div>
                        <div className='mybtn'>
                            <button type="submit" className="login-button">LOGIN</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;