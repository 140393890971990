import React, { useEffect, useState } from 'react';
import '../assets/css/styles.css';
import Header from './Header';
import Sidebar from './Sidebar';
import ChangePasswordForm from './ChangePasswordForm';
import { getMyAccount } from '../services/api';
import { NotificationManager } from 'react-notifications';

const MyAccount = () => {
  const userEmail = localStorage.getItem('loginEmail') || "";

  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [userData, setuserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetAPIcall = async () => {
      try {
        setIsLoading(true);
        var email = userEmail;
        const response = await getMyAccount(email);
        if (response.success) {
          setuserData(response.data);
        } else {
          NotificationManager.error(response.message || 'Something went wrong..Retrive data');
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetAPIcall();
  }, []);


  const handleChangePasswordClick = () => {
    setIsChangingPassword(true);
  };

  const handleCancel = () => {
    setIsChangingPassword(false);
  };

  return (
    <>
      <Header />
      <div className="dashboard-container">
        <div className="dashboard-content">
          <Sidebar />
          {isLoading ? (
            <div className="loading-indicator">
              <div className="spinner"></div>
              <p className="loading-text">Loading...</p>
            </div>
          ) : (
          <main className="main-content">
            <div className="account-container">
              {isChangingPassword ? (
                <ChangePasswordForm onCancel={handleCancel} />
              ) : (
                <div className="account-details">
                  <div className="account-info">
                    <h3>Personal Information</h3>
                    <p><strong>Name:</strong> {userData.name}</p>
                    <p><strong>Email:</strong> {userData.email}</p>
                    <p><strong>Phone:</strong> {userData.phone_number}</p>

                    <div className="account-actions d-flex justify-content-between">
                      <button className="change-password-button w-25 " onClick={handleChangePasswordClick}>Change Password</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </main>
          )}
        </div>
      </div>
    </>
  );
};

export default MyAccount;
