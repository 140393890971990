// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './assets/css/styles.css';
import Header from './pages/Header';
import Sidebar from './pages/Sidebar';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import InvoiceDetails from './pages/InvoiceDetails';
import Home from './pages/Home';
import MyAccount from './pages/MyAccount';

// Define the function
const setDocumentTitle = (title) => {
  document.title = title;
};

const PrivateRoute = ({ children }) => {
  // Replace this with your actual authentication check
  const isAuthenticated = localStorage.getItem('token') !== null;

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    let title = 'React App';

    switch (pathname) {
      case '/dashboard':
        title = 'Dashboard';
        break;
      case '/login':
        title = 'Login';
        break;
      case '/invoice-details':
        title = 'Invoice Details';
        break;
      case '/myaccount':
        title = 'My account';
        break;
      case '/forgot-password':
        title = 'Forgot Password';
        break;
      // Add cases for other routes
      default:
        title = 'Your App Name';
    }

    setDocumentTitle(title);
  }, [location]);
  return (
    <>
      <NotificationContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/invoice-details" element={<PrivateRoute><InvoiceDetails /></PrivateRoute>} />
        <Route path="/myaccount" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
      </Routes>
    </>
  );
}

export default App;
