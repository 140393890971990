import React, { useEffect, useState } from 'react';
import { getInvoiceDetails } from '../services/api';
import Header from './Header';
import Sidebar from './Sidebar';
import '../assets/css/styles.css';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Pagination from './Pagination';

const InvoiceDetails = ({ match }) => {
    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const itemsPerPage = 8;

    const currentYear = new Date().getFullYear();
    const [yearOptions, setYearOptions] = useState([]);
    const [monthOptions] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
    const [levelOptions] = useState(['L1', 'L2', 'L3', 'L4']);

    const [selectedYear, setSelectedYear] = useState("Year");
    const [selectedMonth, setSelectedMonth] = useState("Month");
    const [selectedLevel, setSelectedLevel] = useState("Levels");

    useEffect(() => {
        const fetchAPIcall = async () => {
            setLoading(true);
            try {
                const response = await getInvoiceDetails();
                if (response.success) {
                    const result = filterAndAddLevel(response.data);
                    setEmployees(result);
                    setFilteredEmployees(result);
                    const years = response.data.reduce((acc, item) => {
                        if (!acc.includes(item.Year)) {
                          acc.push(item.Year);
                        }
                        return acc;
                    }, []);                      
                    setYearOptions(years);
                } else {
                    NotificationManager.error(response.message || 'Something went wrong..Retrieve data');
                }
            } catch (error) {
                NotificationManager.error('Error fetching data');
            } finally {
                setLoading(false);
            }
        }
        fetchAPIcall();

    }, []);

    function filterAndAddLevel(data) {
        return data.map(item => {
            let level = item.Designation;
            if (item.Designation.endsWith("- L1")) {
                level = "L1";
            } else if (item.Designation.endsWith("- L2")) {
                level = "L2";
            } else if (item.Designation.endsWith("- L3")) {
                level = "L3";
            } else if (item.Designation.endsWith("- L4")) {
                level = "L4";
            }
            return { ...item, level: level };
        });
    }

    const handleSearch = () => {
        let filtered = employees.filter(emp => {
            const nameMatch = emp.Name.toLowerCase().includes(searchTerm.toLowerCase());
            const idMatch = emp.Vmoksha_Employee_ID.toLowerCase().includes(searchTerm.toLowerCase());
            const yearMatch = selectedYear == "Year" || emp.Year.toString() == selectedYear.toString();
            const monthMatch = selectedMonth == "Month" || emp.Month == selectedMonth;
            const levelMatch = selectedLevel == "Levels" || emp.level == selectedLevel;

            return (searchTerm === '' || nameMatch || idMatch) &&
                   yearMatch && monthMatch && levelMatch;
        });

        setFilteredEmployees(filtered);
        setCurrentPage(1);
    };

    const clearFilters = () => {
        setSearchTerm('');
        setSelectedYear("Year");
        setSelectedMonth("Month");
        setSelectedLevel("Levels");
        setFilteredEmployees(employees);
        setCurrentPage(1);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastEmployee = currentPage * itemsPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
    const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    return (
        <>
            <Header />
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <Sidebar />
                    <main className="main-content">
                        {loading ? (
                            <div className="loading-indicator">
                                <div className="spinner"></div>
                                <p className="loading-text">Loading...</p>
                            </div>
                        ) : (
                            <div className='employee-table-container'>
                                <div className="search-bar">
                                    <div className='d-flex justify-content-between w-50 searchInp'>
                                        <input
                                            className='mr-2'
                                            type="text"
                                            placeholder="Search by Name or Employee ID"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-between w-50 selectDiv'>
                                        <select className='select_bar year w-100' value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                            <option value="Year">Year</option>
                                            {yearOptions.map((year) => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </select>
                                        <select className='select_bar month w-100 mx-2' value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                                            <option value="Month">Month</option>
                                            {monthOptions.map((month, index) => (
                                                <option key={index} value={month}>{month}</option>
                                            ))}
                                        </select>
                                        <select className='select_bar label w-100' value={selectedLevel} onChange={(e) => setSelectedLevel(e.target.value)}>
                                            <option value="Levels">Levels</option>
                                            {levelOptions.map((level) => (
                                                <option key={level} value={level}>{level}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='d-flex justify-content-between w-25 searchDiv'>
                                        <button className="search-button mx-1" onClick={handleSearch}>SEARCH</button>
                                        <button className="clear-button mx-1" onClick={clearFilters}>Clear All</button>
                                    </div>
                                </div>
                                {currentEmployees.length === 0 ? (
                                    <div className='text-center mt-5' style={{ fontSize: '20px', fontWeight: "500" }}><p>No Data Found</p></div>
                                ) : (
                                    <div className='' style={{ overflowX: 'auto', width: '100%' }}>
                                        <div className='outputTables table mt-4'>
                                            <table className="col-12">
                                                <thead style={{ backgroundColor: '#354B96', color: 'white' }}>
                                                    <tr>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>SlNo.</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Vmoksha_Employee_ID</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Name</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Level</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Designation</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Cost_Centre</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Month</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Year</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Profile_Type</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Rate_in_Euro</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Billable_Days_Mon_Fri</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Billable_Days_Sat_Sun</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Total_Billable_Days</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Billable_Days_Second_Shift</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Billable_Days_Night_Shift</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Total_Billing</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Total_Billable_Days_for_Sat_Sunday</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Hardship_Allowance_for_Second_Shift</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Hardship_Allowance_for_Night_Shift</th>
                                                        <th className='py-3 text-center' style={{ whiteSpace: 'nowrap' }}>Total_Billing_Final</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentEmployees.map((employee, index) => (
                                                        <tr key={employee.id}>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.SlNo}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Vmoksha_Employee_ID}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Name}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.level}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Designation}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Cost_Centre}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Month}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Year}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Profile_Type}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Rate_in_Euro}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Billable_Days_Mon_Fri}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Billable_Days_Sat_Sun}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Total_Billable_Days}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Billable_Days_Second_Shift}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Billable_Days_Night_Shift}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Total_Billing}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Total_Billable_Days_for_Sat_Sunday}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Hardship_Allowance_for_Second_Shift}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Hardship_Allowance_for_Night_Shift}</td>
                                                            <td style={{ whiteSpace: 'nowrap' }}>{employee.Total_Billing_Final}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                <div className="pagination">
                                    <Pagination
                                        itemsPerPage={itemsPerPage}
                                        totalItems={filteredEmployees.length}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                    />
                                </div>
                            </div>
                        )}
                    </main>
                </div>
            </div>
        </>
    );
};

export default InvoiceDetails;