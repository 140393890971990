import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { forgotPassword } from '../services/api';
import LoginLogo from '../assets/images/LoginLogo.png';
import '../assets/css/Forgot.css';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!email) {
            setError('Email is required');
            return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setError('Please enter a valid email address');
            return;
        }

        try {
            const response = await forgotPassword(email);
            if (response.success) {
               NotificationManager.success(response.message || 'Password reset link sent to your email');
               navigate('/login');
            }else{
                NotificationManager.error(response.message || 'Forgot api error');
            }
        } catch (error) {
            NotificationManager.error(error.response?.data?.message || 'Error sending reset link');
        }
    };

    const handleInitialPage = () => {
        window.location.href = '/';
    };

    return (
        <div className="forgot-page">
            <div className="forgot-image-section"></div>
            <div className="forgot-section">
                <div className="forgot-container">
                    <div className="logologinforgot">
                        <img src={LoginLogo} alt="UCB Logo" onClick={handleInitialPage} />
                    </div>
                    <h2>FORGET PASSWORD</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor='email'>Email Id </label>
                            <input
                                type="email"
                                placeholder="Enter your Email Id"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {error && <p className="error">{error}</p>}
                        </div>

                        <div className='mybtn'>
                            <button type="submit" className="forgot-button">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
