const API_URL = 'https://ucbempbackend.helenzysinc.com/api/auth';
const Headers = {
  "Content-Type": "application/json",
  'Accept': "application/json",
}
// User Authentication
export const loginUser = async (email, password) => {
  const response = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: Headers,
    body: JSON.stringify({ email, password }),
  });
  return response.json();
};

export const forgotPassword = async (email) => {
  const response = await fetch(`${API_URL}/forgotpassword`, {
    method: 'POST',
    headers:Headers,
    body: JSON.stringify({ email }),
  });
  return response.json();
};

export const updatePassword = async (email, newPassword) => {
  const response = await fetch(`${API_URL}/updatePassword`, {
    method: 'POST',
    headers: Headers,
    body: JSON.stringify({ email, newPassword }),
  });
  return response.json();
};

export const getMyAccount = async (email) => {
  const response = await fetch(`${API_URL}/useraccount`, {
    method: 'POST',
    headers: Headers,
    body: JSON.stringify({ email }),
  });
  return response.json();
};

// Invoice Details
export const getInvoiceDetails = async () => {
  const response = await fetch(`${API_URL}/invoiceDetails`, {
    method: 'GET',
    headers:Headers,
  });
  return response.json();
};

export const getDasnboardInvoiceDetails = async () => {
  const response = await fetch(`${API_URL}/dashboard`, {
    method: 'GET',
    headers:Headers,
  });
  return response.json();
};




